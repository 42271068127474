.config {
    display: flex;
    justify-content: center;
    .field {
        padding: 15px;
        margin: 5px;
        background-color: #CCC;
        label{
            text-align: left;
            
                margin-bottom: 10px;
            input {
                display: block;
                font-size: 14px;
                padding: 4px;
                margin-top: 4px;
            }
        }
        

    }
}

.comandos {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {

        background-color: #CCC;
        border: none;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        margin: 10px;
        cursor: pointer;
        &.start {
            background-color: yellowgreen;
            color: darken($color: yellowgreen, $amount: 50);
        }

        &.clear {
            background: rgb(179, 0, 0);
            color: white;
        }

        &.export {
            
        }

    }
}

.deviceInfo {
    table{
        margin: auto;
        width: 400px;
        border: 1px solid rgb(240, 240, 240);
        tr td {

            &:nth-child(odd){
                
            }
            &:nth-child(even){
                background-color: rgb(240, 240, 240);    
            }
            
            padding: 7px;
            margin: 0;
        }
        
    }
}

.Opts {
    display: flex;
    width: 100%;
    justify-content: center;

    label {
        padding: 10px;
    }
}