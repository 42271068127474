
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App-header {
  background-color: white;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #CCC;
}
.App-body {
  min-height: 80vh;
  max-width: 1000px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.App-logo {
  width: 170px;
  height: auto;
  margin-bottom: 20px;
}

.App-body label {
  display: block;
  height: 40px;
}

.version{
  position: fixed;
  bottom: 4px;
  right: 4px;
  font-size: 12px;
  color: rgb(158, 158, 158);
}

table {
  width: 100%;
  margin-top: 20px;
  font-size: 12px;
  
}

table thead td {
    background-color: #CCC;
    padding: 6px 0 3px 0;
}

table tbody td {
    border-bottom: 1px solid #CCC;
    padding: 4px 0 4px 0;
}

.green{
  background-color: darkgreen;
  color: white;
}
.red{
  background-color: maroon;
  color: white;
}
